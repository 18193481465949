








































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  callAppFunc_goHome,
  callAppFunc_GoWallet,
  callAppFunc_bindWechat,
  callH5Func_wechatCode,
  callAppFunc_receiveTaskCallBack
} from '@/common/utils/bridge'
import SuccessPop from '@/pages/videoGame/components/SuccessPop.vue'
import { getAppLanguage, isIOS, unitFormat } from '@/common/utils'
import { receiveTaskAward, commitSign, wechatAuth } from '@/pages/videoGame/api'
import { CommonModule } from '@/store/modules/common'
import TaskFooter from './TaskFooter.vue'
import { receiveAllTaskByType } from '@/pages/videoGame/api'
@Component({
  components: {
    SuccessPop,
    TaskFooter
  }
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {
        sign: 0,
        signConfigs: [],
        signDay: 1,
        taskVOS: [],
        taskWechatNickname: '',
        userVipLevel: '',
        playTypeVOS: []
      }
    }
  })
  vipTaskInfo!: any

  get canReceiveNum() {
    let res = 0
    if (this.vipTaskInfo.playTypeVOS.length) {
      res = this.vipTaskInfo.playTypeVOS.filter(
        (item: any) => item.playTaskType == 6
      )[0].canReceiveCoin
    }
    return res
  }
  get canReceiveNum2() {
    let res = 0
    if (this.vipTaskInfo.playTypeVOS.length) {
      res = this.vipTaskInfo.playTypeVOS.filter(
        (item: any) => item.playTaskType == 8
      )[0].canReceiveCoin
    }
    return res
  }
  get canReceiveNum3() {
    let res = 0
    if (this.vipTaskInfo.playTypeVOS.length) {
      res = this.vipTaskInfo.playTypeVOS.filter(
        (item: any) => item.playTaskType == 5
      )[0].canReceiveCoin
    }
    return res
  }
  get canReceiveNum4() {
    let res = 0
    if (this.vipTaskInfo.playTypeVOS.length) {
      res = this.vipTaskInfo.playTypeVOS.filter(
        (item: any) => item.playTaskType == 4
      )[0].canReceiveCoin
    }
    return res
  }

  @Prop()
  activeTab!: number
  @Watch('activeTab')
  handleActiveTabChange(val: any) {
    if (val == 2) {
      this.infoChange()
    }
  }
  async infoChange() {
    await this.$nextTick()
    let isAutoScrool = false
    this.vipTaskInfo.signConfigs.forEach((item: any) => {
      if (item.day == this.vipTaskInfo.signDay && item.receive == 0) {
        isAutoScrool = true
      }
    })
    console.log('isAutoScrool', isAutoScrool)
    if (isAutoScrool) {
      await this.$nextTick()
      this.autoScrool()
    }
  }

  unitFormat = unitFormat
  language = getAppLanguage()
  showSuccess = false
  successTitle: any = ''
  successContent: any = ''
  showCaushPop = false
  showWxPop = false
  curTab = 1
  receiveBtnLoading = false
  taskList(curTab: number) {
    if (curTab == 1) {
      return this.vipTaskInfo.taskVOS.filter(
        (item: any) => item.playTaskType == 6 || item.playTaskType == 7
      )
    } else if (curTab == 2) {
      return this.vipTaskInfo.taskVOS.filter(
        (item: any) => item.playTaskType == 8
      )
    } else if (curTab == 3) {
      return this.vipTaskInfo.taskVOS.filter(
        (item: any) => item.playTaskType == 5
      )
    } else {
      return this.vipTaskInfo.taskVOS.filter(
        (item: any) => item.playTaskType == 4
      )
    }
  }
  handleShowSuccessPop() {
    this.showSuccess = true
  }
  autoScrool() {
    const dayNode: any = document.getElementById(
      `signDay_${this.vipTaskInfo.signDay}`
    )
    console.log('dayNode', dayNode)
    dayNode.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }
  finishTaskInfo: any = {
    id: '',
    awardType: '',
    awardNum: ''
  }

  get signTips() {
    let curSignDay = this.vipTaskInfo.signDay || 0
    if (!this.vipTaskInfo.sign) {
      curSignDay = curSignDay == 0 ? 0 : curSignDay - 1
    }
    const total = this.vipTaskInfo.signConfigs.filter(
      (item: any) => item.receive != 2
    ).length
    if (this.language == 'zh_CN') {
      return (
        '已连续签到' + curSignDay + '天' + '(' + curSignDay + '/' + total + ')'
      )
    } else {
      return 'dawamlik yoklimidn otkan kun sanigiz ' + curSignDay + ' kun'
    }
  }
  showFailPop = false
  failReason: any = ''
  toDoTask(task: any) {
    if (task.playTaskType == 2 || task.playTaskType == 8) {
      callAppFunc_goHome()
    } else {
      if (isIOS) {
        callAppFunc_GoWallet()
      } else {
        this.$router.push('/wallet')
      }
    }
  }
  finishedLoading = false
  async finishTask(task: any) {
    this.$emit('handleFinishTask', task)
  }
  handlCash(task: any) {
    this.$emit('handlCash', task)
  }
  callAppFunc_bindWechat = callAppFunc_bindWechat

  async handleSign(signDay: any) {
    this.$emit('handleSign', {
      dayInfo: signDay,
      signDay: this.vipTaskInfo.signDay
    })
  }
  handlLock(item: any) {
    const text =
      this.language == 'zh_CN'
        ? `升级至VIP${item.vipLevel}可解锁`
        : `VIP darijisi ${item.vipLevel} ga toxkandin keyin eqilidu`
    this.$toast(text)
  }

  async commitReceiveAllTask(playTaskType: number) {
    if (this.receiveBtnLoading) return
    this.receiveBtnLoading = true
    const params = {
      taskType: 2,
      playTaskType
    }
    try {
      const { data } = await receiveAllTaskByType(params)
      this.$emit('afterReceiveAll', data)
    } finally {
      this.receiveBtnLoading = false
    }
  }
  async mounted() {
    //this.infoChange()
  }
}
