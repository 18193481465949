
















import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
@Component
export default class extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  show!: boolean
  @Prop({
    type: String,
    default: false
  })
  showTitle!: string
  @Prop({
    type: String,
    default: false
  })
  showContent!: string
  curShow = false
  @Watch('show')
  showChange(val: boolean) {
    this.curShow = val
  }
  closePop() {
    this.curShow = false
    this.$emit('closePop')
  }
}
