






















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: 0 }) reviceNum!: number

  handleRevice() {
    if (this.reviceNum > 0) {
      this.$emit('revice')
    }
  }
}
