


































































































































import { Component, Vue } from 'vue-property-decorator'
import DayTask from './components/DayTask.vue'
import VipTask from './components/VipTask.vue'
import {
  getTaskList,
  wechatAuth,
  receiveTaskAward,
  commitSign
} from '@/pages/videoGame/api'
import { CommonModule } from '@/store/modules/common'
import { getAppLanguage, unitFormat, isIOS, compare } from '@/common/utils'
import {
  callAppFunc_closeCurrentWebview,
  callH5Func_wechatCode,
  callAppFunc_bindWechat,
  callAppFunc_receiveTaskCallBack,
  callAppFunc_setNavColor
} from '@/common/utils/bridge'
import SuccessPop from '@/pages/videoGame/components/SuccessPop.vue'
@Component({
  components: {
    DayTask,
    VipTask,
    SuccessPop
  }
})
export default class extends Vue {
  callAppFunc_closeCurrentWebview = callAppFunc_closeCurrentWebview
  callAppFunc_bindWechat = callAppFunc_bindWechat
  showVersionPop = false
  activeTab = 1
  dayReceiveNum = 0
  vipReceiveNum = 0
  dayTaskInfo: any = {
    sign: 0,
    signConfigs: [],
    signDay: 1,
    taskVOS: [],
    taskWechatNickname: '',
    userVipLevel: ''
  }
  vipTaskInfo: any = {
    sign: 0,
    signConfigs: [],
    signDay: 1,
    taskVOS: [],
    taskWechatNickname: '',
    userVipLevel: '',
    playTypeVOS: []
  }
  showCaushPop = false
  showWxPop = false
  language = getAppLanguage()
  finishTaskInfo: any = {
    id: '',
    awardType: '',
    awardNum: ''
  }
  showSuccess = false
  successTitle: any = ''
  successContent: any = ''
  tabChange(val: number) {
    this.activeTab = val
  }
  async getList() {
    const params = {
      taskType: 1,
      uid: CommonModule.userId
    }
    const { data } = await getTaskList(params)
    this.dayReceiveNum = data.dayReceiveNum
    this.vipReceiveNum = data.vipReceiveNum
    Object.assign(this.dayTaskInfo, data)
  }
  async getVipTask() {
    const params = {
      taskType: 2,
      uid: CommonModule.userId
    }
    const { data } = await getTaskList(params)
    Object.assign(this.vipTaskInfo, data)
  }
  init() {
    this.getList()
    this.getVipTask()
  }
  compareVersion = compare
  handlCash(task: any) {
    if (this.dayTaskInfo.taskWechatNickname) {
      this.showCaushPop = true
    } else {
      this.showWxPop = true
    }
    this.finishTaskInfo.id = task.id
    this.finishTaskInfo.awardType = task.awardType
    this.finishTaskInfo.awardNum = task.awardNum
  }
  handlBindBack(val: any) {
    this.showWxPop = false
    this.handlewechatAuth(val)
  }
  async handlewechatAuth(code: string) {
    const params = {
      code: code,
      uid: CommonModule.userId,
      taskBind: 1
    }
    const { data } = await wechatAuth(params)
    console.log('handlewechatAuth res', data)
    this.init()
    this.$toast(this.$t('授权成功'))
    await this.$nextTick()
    console.log('this.showCaushPop0000', this.showCaushPop)
    this.showCaushPop = true
    console.log('this.showCaushPop1111', this.showCaushPop)
  }
  showFailPop = false
  failReason: any = ''
  handleShowSuccessPop() {
    this.showSuccess = true
  }
  finishedLoading = false
  async finishTask(task: any) {
    if (this.finishedLoading) return
    this.finishedLoading = true
    const params = {
      taskId: task.id
    }
    try {
      const { data } = await receiveTaskAward(params)

      this.init()
      callAppFunc_receiveTaskCallBack()
      if (data) {
        this.showCaushPop = false
        this.successTitle =
          task.awardType == 1 ? this.$t('领取成功') : this.$t('提现成功')
        if (task.awardType == 1) {
          if (this.language == 'zh_CN') {
            this.successContent =
              this.$t('恭喜你') +
              '，' +
              this.$t('获取') +
              task.awardNum +
              this.$t('金币')
          } else {
            this.successContent =
              'mubarak bolsun,' + task.awardNum + 'taggiga erixtigiz'
          }
        } else {
          if (this.language == 'zh_CN') {
            this.successContent =
              this.$t('恭喜你') +
              '，' +
              this.$t('提现成功') +
              task.awardNum +
              this.$t('元')
          } else {
            this.successContent =
              'mubarak bolsun, ' +
              task.awardNum +
              'yuan naklaxturux oguxluk boldi'
          }
        }
        this.handleShowSuccessPop()
      }
    } catch (err) {
      const error: any = err
      if (error.code == 4004) {
        this.failReason = error.message
        this.showFailPop = true
        return
      }
    } finally {
      this.finishedLoading = false
    }
  }
  signLoading = false
  async handleSign(emitData: any) {
    console.log('handleSign emitData', emitData)
    const dayInfo = emitData.dayInfo
    const signDay = emitData.signDay
    if (dayInfo.day !== signDay) return

    if (this.signLoading) return
    this.signLoading = true
    const params = {
      uid: CommonModule.userId,
      day: signDay
    }
    try {
      const { data } = await commitSign(params)
      callAppFunc_receiveTaskCallBack()
      this.init()
      if (data) {
        this.successTitle = this.$t('签到成功')

        this.successContent =
          this.$t('恭喜你') +
          '，' +
          this.$t('获取') +
          dayInfo.propNum +
          this.$t('金币')

        this.handleShowSuccessPop()
      }
    } finally {
      this.signLoading = false
    }
  }
  afterReceiveAll(data: any) {
    callAppFunc_receiveTaskCallBack()
    this.successTitle = this.$t('领取成功')

    this.successContent =
      this.$t('恭喜你') + '，' + this.$t('获取') + data + this.$t('金币')

    this.handleShowSuccessPop()
    this.init()
  }
  async mounted() {
    callAppFunc_setNavColor({ navColor: '#F5F5F5' })
    callH5Func_wechatCode(this.handlBindBack)
    await CommonModule.getToken()
    // if (!this.compareVersion(CommonModule.appVersion, '3.5.0')) {
    //   this.showVersionPop = true
    // }
    this.init()
  }
  destroyed() {
    callAppFunc_setNavColor({ navColor: '#ffffff' })
  }
}
