















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  callAppFunc_goHome,
  callAppFunc_GoWallet,
  callAppFunc_bindWechat,
  callH5Func_wechatCode,
  callAppFunc_receiveTaskCallBack
} from '@/common/utils/bridge'
import SuccessPop from '@/pages/videoGame/components/SuccessPop.vue'
import { getAppLanguage, isIOS, unitFormat } from '@/common/utils'
import { receiveTaskAward, commitSign, wechatAuth } from '@/pages/videoGame/api'
import { CommonModule } from '@/store/modules/common'
import TaskFooter from './TaskFooter.vue'
import { receiveAllTaskByType } from '@/pages/videoGame/api'
@Component({
  components: {
    SuccessPop,
    TaskFooter
  }
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {
        sign: 0,
        signConfigs: [],
        signDay: 0,
        taskVOS: [],
        taskWechatNickname: '',
        userVipLevel: ''
      }
    }
  })
  dayTaskInfo!: any
  language = getAppLanguage()
  showSuccess = false
  successTitle: any = ''
  successContent: any = ''
  showCaushPop = false
  showWxPop = false
  unitFormat = unitFormat
  finishTaskInfo: any = {
    id: '',
    awardType: '',
    awardNum: ''
  }

  get signTips() {
    let curSignDay = this.dayTaskInfo.signDay || 0
    if (!this.dayTaskInfo.sign) {
      curSignDay = curSignDay == 0 ? 0 : curSignDay - 1
    }
    if (this.language == 'zh_CN') {
      return '已连续签到' + curSignDay + '天' + '(' + curSignDay + '/7)'
    } else {
      return 'dawamlik yoklimidn otkan kun sanigiz' + curSignDay + 'kun'
    }
  }

  showFailPop = false
  failReason: any = ''
  receiveBtnLoading = false
  handleShowSuccessPop() {
    this.showSuccess = true
  }
  toDoTask(task: any) {
    if (task.playTaskType == 2) {
      callAppFunc_goHome()
    } else {
      if (isIOS) {
        callAppFunc_GoWallet()
      } else {
        this.$router.push('/wallet')
      }
    }
  }
  finishedLoading = false
  async finishTask(task: any) {
    this.$emit('handleFinishTask', task)
  }
  handlCash(task: any) {
    this.$emit('handlCash', task)
  }
  callAppFunc_bindWechat = callAppFunc_bindWechat
  async text() {
    this.showWxPop = false
    this.$emit('refresh')
    this.$toast(this.$t('授权成功'))
    await this.$nextTick()
    this.showCaushPop = true
  }

  signLoading = false
  async handleSign(signDay: any) {
    console.log('daytask  handleSign')
    this.$emit('handleSign', {
      dayInfo: signDay,
      signDay: this.dayTaskInfo.signDay
    })
  }
  handlLock(item: any) {
    const text =
      this.language == 'zh_CN'
        ? `升级至VIP${item.vipLevel}可解锁`
        : `VIP darijisi ${item.vipLevel} ga toxkandin keyin eqilidu`
    this.$toast(text)
  }
  async commitReceiveAllTask() {
    if (this.receiveBtnLoading) return
    this.receiveBtnLoading = true
    const params = {
      taskType: 1
    }
    try {
      const { data } = await receiveAllTaskByType(params)
      this.$emit('afterReceiveAll', data)
    } finally {
      this.receiveBtnLoading = false
    }
  }
  mounted() {
    //callH5Func_wechatCode(this.handlBindBack)
  }
}
